<template>
  <v-container fluid class="ma-0 pa-0 fill-height white">
    <div class="smooth-scroll">

      <section class="min-h-100-lg" data-scroll-section id="home">
        <div class="section-inner black">
          <Homepage @scroll-to="scrollTo" @start-free="startFree" />
        </div>
        <div class="section-inner">
          <Security @start-free="startFree" />
        </div>
      </section>

      <section class="horizontalScrolling black" data-scroll-section ref="horizontalSection" id="product">
        <div class="section-inner">
          <WhatFind @start-free="startFree" @support="support" />
        </div>
      </section>


      <section class="white min-h-100 afterHorizontal" data-scroll-section id="price">
        <div class="section-inner"></div>
        <div class="section-inner min-h-100-lg">
          <Price @scroll-to="scrollTo" @refreshScroll="refreshLocomotiveScroll" />
        </div>

        <div class="section-inner min-h-100-lg">
          <ContestProcess />
        </div>

        <div class="section-inner min-h-100-lg">
          <ContestProcess2 @control-scroll="handleScrollControl" />
        </div>

        <div class="section-inner" id="faqs">
          <Questions @scroll-to="scrollTo" @refreshScroll="refreshLocomotiveScroll" />
        </div>

        <div class="section-inner" id="contacts">
          <Contact />
        </div>
      </section>

      <section class="horizontalScrolling horizontalScroll2" data-scroll-section ref="horizontalSection2" v-if="true">
        <div class="section-inner">
          <Clients />
          <Footer @scroll-to="scrollTo" @terms="openTerms" @privacy="openPolicy" />
        </div>
      </section>

      <v-dialog v-model="policy" fullscreen persistent>
        <v-card class="black">
          <Policy @close="closePolicy" />
        </v-card>
      </v-dialog>

      <v-dialog v-if="terms" v-model="terms" fullscreen persistent>
        <v-card class="black">
          <TermsConditions @close="closeTerms" />
        </v-card>
      </v-dialog>

    </div>
    <v-btn @click="startFree" class="elevation-24 d-lg-none btn-start-free" block color="black" tile large
      style="position: fixed; bottom: 0; z-index: 1000;">{{ $t('footer.free_button') }}</v-btn>
  </v-container>
</template>
<script>
import Homepage from "@/views/Homepage.vue"
import Security from "@/views/Security.vue"
import WhatFind from "@/views/WhatFind.vue"
import Clients from "@/views/Clients.vue"
import TermsConditions from '@/views/TermsConditions.vue'
import ContestProcess from "@/views/ContestProcess.vue"
import ContestProcess2 from "@/views/ContestProcess2.vue"
import Price from "@/views/Price.vue"
import Questions from "@/views/Questions.vue"
import Contact from "@/views/Contact.vue"
import Footer from "@/views/Footer.vue"
import LocomotiveScroll from "locomotive-scroll";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import Policy from '@/views/Policy.vue'


export default {
  name: "Father",
  props: {
    section: String
  },
  data() {
    return {
      locoScroll: null,
      hideSection: false,
      policy: false,
      terms: false
    };
  },
  components: {
    Homepage,
    Security,
    WhatFind,
    ContestProcess,
    TermsConditions,
    ContestProcess2,
    Price,
    Questions,
    Contact,
    Footer,
    Policy,
    Clients
  },
  watch: {
    '$route': {
      immediate: true,
      handler(val) {
        if (val.path.includes(this.$t('footer.link_politica')))
          this.policy = true

        if (val.path.includes(this.$t('footer.link_termos')))
          this.terms = true
      }
    },
    section: {
      immediate: true,
      handler(l) {
        if (l == null)
          return

        this.scrollTo(l)
      }
    }
  },
  methods: {
    closePolicy() {
      this.$router.push('/' + this.$i18n.locale)
      this.policy = false
    },
    closeTerms() {
      this.$router.push('/' + this.$i18n.locale)
      this.terms = false
    },
    openPolicy() {
      this.$router.push('/' + this.$i18n.locale + '/' + this.$t('footer.link_politica'))
    },
    openTerms() {
      this.$router.push('/' + this.$i18n.locale + '/' + this.$t('footer.link_termos'))
    },
    startFree() {
      window.open(process.env.VUE_APP_BACKOFFICE + this.$i18n.locale + '/register/start-free', '_blank')
    },
    support() {
      let target = ''
      this.hideSection = true
      target = document.querySelector('#contacts');
      if (window.innerWidth >= 1263)
        this.locoScroll.scrollTo(target)

      this.hideSection = false
    },
    scrollTo(place) {
      let target = ''
      if (place == '#privacy') {
        this.policy = true
      }

      if (place == '#terms') {
        this.terms = true
      }

      if (place == '') {
        this.hideSection = true
        target = document.querySelector('#home');
      }

      if (place == '#product') {
        target = document.querySelector('#product');
      }

      if (place == '#price') {
        this.hideSection = true
        target = document.querySelector('#price');
      }

      if (place == '#faqs') {
        this.hideSection = true
        target = document.querySelector('#faqs');
      }

      if (place == '#contacts') {
        this.hideSection = true
        target = document.querySelector('#contacts');
      }

      if (window.innerWidth >= 1263){
        this.locoScroll.scrollTo(target)
      }
      else {
        window.location.hash = place;
      }

      this.hideSection = false
    },
    setLocomotiveScroll() {
      console.clear();
      const that = this;

      gsap.registerPlugin(ScrollTrigger);



      // Using Locomotive Scroll from Locomotive https://github.com/locomotivemtl/locomotive-scroll

      this.locoScroll = new LocomotiveScroll({
        el: document.querySelector(".smooth-scroll"),
        smooth: true
      });
      // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
      this.locoScroll.on("scroll", ScrollTrigger.update);

      // tell ScrollTrigger to use these proxy methods for the ".smooth-scroll" element since Locomotive Scroll is hijacking things
      ScrollTrigger.scrollerProxy(".smooth-scroll", {
        scrollTop(value) {
          return arguments.length ? that.locoScroll.scrollTo(value, 0, 0) : that.locoScroll.scroll.instance.scroll.y;
        }, // we don't have to define a scrollLeft because we're only scrolling vertically.
        getBoundingClientRect() {
          return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
        },
        // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
        pinType: document.querySelector(".smooth-scroll").style.transform ? "transform" : "fixed"
      });



      const sections = gsap.utils.toArray('section')

      sections.forEach(function (section) {

        const inner = section.querySelector('.section-inner');

        if (!section.classList.contains('horizontalScrolling')) {

          ScrollTrigger.create({

            scroller: '.smooth-scroll',
            trigger: section,
            start: section.scrollHeight <= window.innerHeight ? 'top top' : 'bottom bottom',
            end: '+=100%',
            pin: inner,
            pinSpacing: false,
            pinType: 'transform',
            onEnter: () => console.log('Trigger enter', section.id),
            onLeave: () => console.log('Trigger leave', section.id)

          })

        } else {

          const scroll = section.querySelector('[data-scroll-in-section]');
          console.log(section.scrollWidth);
          ScrollTrigger.create({

            scroller: '.smooth-scroll',
            trigger: section,
            start: 'center center',
            end: () => `+=${section.scrollWidth + window.innerHeight}`,
            pin: inner,
            pinSpacing: true,
            pinType: 'transform',
            anticipatePin: 1,
            onEnter: () => console.log('Trigger enter', section.id),
            onLeave: () => console.log('Trigger leave', section.id)

          })

          gsap.to(scroll, {
            x: () => `${-(section.scrollWidth - document.documentElement.clientWidth + 200)}px`,
            ease: 'none',
            scrollTrigger: {
              trigger: scroll,
              scroller: '.smooth-scroll',
              start: 'center center',
              end: () => `+=${section.scrollWidth}`,
              scrub: true,
              onUpdate: (self) => {
                const progress = self.progress;
                const scrolledPercentage = progress * 100;
                that.updateProgressBar(scrolledPercentage);
              }
            }
          });

        }


      })
      ScrollTrigger.addEventListener("refresh", () => this.locoScroll.update());
      ScrollTrigger.refresh();
    },
    handleScrollControl(enable) {
      if (enable) {
        this.locoScroll.start();
      } else {
        this.locoScroll.stop();
      }
    },
    updateProgressBar(scrolledPercentage) {
      this.$emit('update-progress', scrolledPercentage);
    },
    refreshLocomotiveScroll() {
      // Atualiza os cálculos do Locomotive Scroll
      if (this.locoScroll) {
        this.locoScroll.update();
      }
      // Atualiza os cálculos do ScrollTrigger
      ScrollTrigger.refresh();
    }
  },
  mounted() {
    if (window.innerWidth >= 1263) {
      this.setLocomotiveScroll();
    }
    console.log("ENTRADA")
    console.log(this.$route)
    this.$i18n.locale = this.$route.params.lang
  },
}
</script>
<style>
body {
  font-family: 'Lato';
  overflow-x: hidden;
}

.smooth-scroll {
  overflow: hidden !important;
}

.min-h-50 {
  min-height: 50vh;
}

.min-h-100 {
  min-height: 100vh;
}

section {
  width: 100vw;
}

.horizontalScrolling:not(.horizontalScroll2) {
  padding-top: 125px;
  padding-bottom: 125px;
}

.horizontalScrolling .section-inner {
  min-height: max-content;
}

[data-scroll-in-section] {
  display: inline-flex;
  padding-right: 50px;
  width: 100%;
}

.sectionLeftAndRight .section-inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
}


section.afterHorizontal {
  margin-top: -100vh;
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-section] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}

.c-scrollbar:hover {
  transform: scaleX(1.45);
}

.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
}

.neonText {
  color: #fff;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.7) !important;
}

.neonText-hover:hover {
  color: #fff;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.7) !important;
}

.neonBox {
  box-shadow: #FFFFFF 0px 22px 70px 4px;
}

.neonBoxRound {
  box-shadow: #FFFFFF 0px 0px 21px -2px;
  border-radius: 50%;
}

.min-w-100 {
  min-width: 100%;
}

.v-card .v-card__text {
  padding: 12px;
}

.theme--dark.v-pagination .v-pagination__item--active{
  border: 1px solid black;
  border-color: black !important;
  background-color: white !important;
  color: black !important;
}

.absolute-expansion-panel{
    position: absolute;
    background-color: #1e1e1e;
    top: 42px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  
  .v-card__title{
    word-break: auto-phrase !important;
  }

@media screen and (min-width: 1264px) {
  #price {
    margin-bottom: -40vh;
  }

  .min-h-100-lg {
    min-height: 100vh;
  }
}

@media only screen and (max-width: 1263px) {
  body {
    overflow: auto;
    height: 100vh;
  }

  section.afterHorizontal {
    margin-top: 0;
  }

  .horizontalScrolling:not(.horizontalScroll2) {
    padding: 32px 0;
  }

  .v-application .black.btn-start-free {
    border-top: 2px solid #fff !important;
    border-color: #fff !important;
    height: 50px !important;
  }
}
</style>